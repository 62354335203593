@import "settings/variables.scss";
.image-wrapper {
    //margin: -80px 50px 20px -50px;
    position: relative;
    padding-bottom: 56.2%;
    display: flex;

    img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
.blogContainer{
    .blogItem{
        background-color: $backgroundColor;
        margin: 80px 0 30px 50px;
        text-align: center;
        height: calc(100% - 110px);
        padding-bottom: 40px;


        .blogItemImage{
            margin: -80px 50px 20px -50px;
            width: 100%;
            object-fit: cover;
            height: 160px;
            box-shadow: 0 3px 6px lightgrey;

            @media only screen and (min-width: 768px){
                height: 144px;
            }

            @media only screen and (min-width: 992px){
                height: 120px;
            }

            @media only screen and (min-width: 1200px){
                height: 150px;
            }
        }

        position:relative;

        h2{
            font-size:25px;
        }

        p{
            font-size: 14px;
        }

        .blogItemLink{
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

}

.sectors{
    .blogContainer{
        .blogItem{
            .blogItemImage{
                height: 200px;

                //@media only screen and (min-width: 768px){
                //    height: 280px;
                //}
            }
        }
    }
}

.relatedBlogs{
    .blogContainer{
        .blogItemImage{
            height: 180px;

            @media only screen and (min-width: 768px){
                height: 140px;
            }
        }

        h3{
            color: $text-color;
        }
    }
}

.featuredNews{
    background-color: $backgroundColor;
    padding: 50px 0;
}
