
#_cbhtml > div.is-pop.quickadd.arrow-top.center > div:nth-child(3) > button.add-more.classic {
    display: none;
}

#_cbhtml > div.is-pop.quickadd.arrow-top.left > div:nth-child(3) > button.add-more.classic{
    display: none;
}

#_cbhtml > div.is-pop.quickadd.arrow-top.left > div.is-pop-tabs{
    display: none !important;
}
#_cbhtml > div.rte-more-options.is-rte-pop.active > div > button.previewcontent-button {
    display: none !important;
}
@media (min-width: 640px){
    .is-builder > div {
        display: block !important;
    }
}

@media (max-width: 40rem){
    .column{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.contentbuilder{
    ul,p{
        a{
            color: $mainColor;
            transition: all .3s;

            &:focus, &:active, &:visited{
                color: $mainColor !important;
            }

            &:hover{
                color: $text-color !important;
                text-decoration: none;
            }
        }
        li{
            list-style: disc;
            font-family: fira-sans, sans-serif;
            color: $text-color;
            font-size: 16px;
        }

        i{
            font-style: italic;
        }
    }

    ul{
        list-style: inside;
        margin-left: 20px;
    }

    ol{
        li{
            list-style: decimal;
            font-family: fira-sans, sans-serif;
            color: $text-color;
            font-size: 16px;

        }
        margin-left: 20px;
    }
}
