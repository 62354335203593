.customForm{
    .form-group{
        label{
            font-family: $font-family;
            color: $text-color;
            font-size: 16px;
        }

        .form-control{
            border-radius: 0;
            border-color: $subColor;
        }
    }

    .custom-checkbox {
        .custom-control-input:checked ~ .custom-control-label:after {
            background-color: $mainColor;
            border: none;
            border-radius: 0;
            outline: none;
        }

        .custom-control-label::before{
            border-radius: 0 !important;
            border-color: $subColor;
        }

        .custom-control-label{
            font-family: $font-family;
            color: $text-color;
            font-size: 16px;
        }
    }


}

.alert{
    border-radius: 0 !important;
}

.alert-danger{
    background-color: $white !important;
    color: red !important;
    border-color: red !important;
}

