#myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: $text-color; /* Set a background color */
    color: $white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 10px; /* Some padding */
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 1px 2px 7px rgba(0,0,0,0.3);
    transition: all .3s;

    &:hover {
        transition: all .3s;
        box-shadow: none;
    }

    img{
        height: 25px;
    }
}
