@import "settings/variables.scss";

.button{
    padding: 5px 20px 5px 20px;
    color: $white;
    display: inline-block;
    font-family: $font-family-sub;
    border: none;
    position: relative;
    font-size: 20px;

    &:hover{
        color: $white;
        text-decoration: none;
    }

    &:visited{
        color: $white;
    }
}

.button-accent{
    background-color: $accentColor;
    transition: all .3s;
    overflow: hidden;
    position: relative;

    &:after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .5;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
    }

    &:hover{
        &:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
}

.button-main{
    background-color: $mainColor;
    transition: all .3s;
    overflow: hidden;
    position: relative;

    &:after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .5;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
    }

    &:hover{
        &:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
}

.button-link{
    color: $mainColor;
    transition: all .3s;

    &:focus, &:active, &:visited{
        color: $mainColor !important;
    }

    &:hover{
        color: $text-color !important;
        text-decoration: none;
    }


}
