@import "settings/variables.scss";

.headerNavigation {
    .topbar, .bottombar {
        .level_root.horizontal {
            .menu_item {
                a {
                    color: $text-color;
                    font-size: 18px;
                }
            }
        }
    }

    @media only screen and (min-width: 992px) {
        .topbar {
            padding: 10px;
            background-color: $subColor;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .level_root.horizontal {
                .menu_item {
                    padding: 0 15px !important;

                    a{
                        transition: color .3s;
                        &:hover{
                            text-decoration: none;
                            color: $mainColor;
                        }
                    }
                }
            }
        }

        .bottombar {
            padding: 50px 0;

            .level_root.horizontal {
                .menu_item {
                    padding: 0 0 0 25px !important;

                    a {
                        position: relative;
                        transition: color .2s ease;
                        color: $mainColor;
                        font-size: 20px;

                        &:hover {
                            color: $text-color;
                            text-decoration: none;

                            &::after, &::before {
                                width: 100%;
                                left: 0;
                            }
                        }

                        &::after, &::before {
                            content: '';
                            position: absolute;
                            top: calc(100% + 5px);
                            width: 0;
                            right: 0;
                            height: 3px;
                        }

                        &::before {
                            transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
                            background: $mainColor;
                        }

                        &::after {
                            transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
                            background: $text-color;
                        }

                    }
                }
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .navbar-collapse {
            padding: 40px 0;

            .level_root.horizontal {
                display: block;
            }
        }
    }

    .navbar-brand {
        @media only screen and (min-width: 992px) {
            padding: 30px 0;
        }

        @media only screen and (max-width: 992px) {
            max-width: 220px;
        }
    }

}

// Hamburger menu toggler
.second-button {
    border: none !important;

    &:focus {
        outline: none;
    }
}

.animated-icon2 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    display: block;
}

.animated-icon2 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.animated-icon2 span {
    background: $mainColor;
}

.animated-icon2 span:nth-child(1) {
    top: 0px;
}

.animated-icon2 span:nth-child(2), .animated-icon2 span:nth-child(3) {
    top: 10px;
}

.animated-icon2 span:nth-child(4) {
    top: 20px;
}

.animated-icon2.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}

.animated-icon2.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}
