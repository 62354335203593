@import "settings/variables.scss";

.eskofin-blue{
    padding: 40px !important;
    background-color: $mainColor;
    h2, span, p{
        color: $white;
    }

}

.eskofin-light-gray{
    padding: 40px !important;
    background-color: $subColor;
    h2, span, p{
        color: $text-color;
    }
}

.eskofin-dark-gray{
    padding: 40px !important;
    background-color: $text-color;
    h2, span, p{
        color: $white;
    };
}

.jobImage{
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.relatedJobs{
    .relatedJobContainer{
        background-color: $subColor;
        margin-bottom: 40px;

        a{
            margin-bottom: -25px;
        }
    }
}
