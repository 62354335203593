@import "settings/variables.scss";

.sidebarDiensten{
    @media only screen and (min-width: 992px) {
        margin-top: -50px;
    }
    margin-top: 40px;


    .sidebarDienstenBg{
        padding: 50px 25px !important;
        background-color: $backgroundColor;
        border-bottom-right-radius: 30px;

        .sidebarDienstenLink{
            transition: all .3s;

            &:hover{
                padding-left: 16px;
            }


        }
    }
}

.expertiseContainer{
    .expertiseItem{
        position: relative;

        .image-wrapper {
            position: relative;
            padding-bottom: 56.2%;

            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }


        .contentContainer{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,72,151,.4);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            h2{
                color:white;
            }
        }
    }
}
