@import "settings/variables.scss";


.homeCarousel.carousel{
        .carousel-inner{
            .carousel-item{
                img{
                    @media only screen and (min-width: 768px){
                        height: 800px;
                    }
                    height: 500px;
                    width: 100vw;
                    object-fit: cover;
                }

                .carousel-caption{
                    background-color: rgba(0,72,151,.3);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    text-align: left;
                    display: flex;
                    align-items: center;

                    div{
                        @media only screen and (min-width: 768px){
                            margin-top: 80px;
                        }

                        .eskofin{
                            font-family: rogue-serif, serif;
                            font-size: 20px;
                        }

                        h2{
                            max-width: 400px;
                            margin-bottom: 180px;
                        }
                    }



                    //@media only screen and (min-width: 768px){
                    //    padding: 50px 7vw 50px 0;
                    //}


                    //.carouselContentDetail{
                    //    @media only screen and (min-width: 768px){
                    //        padding: 20px 20px 20px 140px;
                    //        margin-left: -90px;
                    //        margin-right: 120px;
                    //    }
                    //    padding: 20px 20px 20px 20px;
                    //
                    //    background-color: rgba(255,255,255,.5);
                    //}

                    //.mvoContainer{
                    //    @media only screen and (min-width: 768px){
                    //        display: flex;
                    //        margin-right: 120px;
                    //    }
                    //
                    //    margin-top: 20px;
                    //
                    //    .mvoContainerContent{
                    //        background-color: rgba(255,255,255,.5);
                    //        @media only screen and (min-width: 768px){
                    //            padding: 30px 110px 30px 30px;
                    //            text-align: left;
                    //        }
                    //        padding: 30px 30px 30px 30px;
                    //        margin-top: 50px;
                    //        margin-left: auto;
                    //        text-align: right;
                    //
                    //        a{
                    //            font-family: $font-family-sub;
                    //            font-size: 25px;
                    //            color: $mainColor
                    //        }
                    //    }
                    //    img{
                    //        height: 150px;
                    //        margin-bottom: 50px;
                    //        @media only screen and (min-width: 768px){
                    //            margin-left: -85px;
                    //            margin-top: 0px;
                    //        }
                    //        margin-top: -20px;
                    //        margin-left: 70px;
                    //    }
                    //}
                }
            }
        }
    }

