@import "settings/variables.scss";

.page_title{
    background-color: $mainColor;
    margin: 15px 0 50px 0;

    h1{
        color: $white;
        text-align: center;
        padding: 10px;
    }
}

.page_title_mvo{
    background-color: $accentColor;
}
