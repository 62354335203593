@import "settings/variables.scss";

.eskofinContentblock{
    margin: 50px 0;
    .eskofinleft{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 10px 0;
        padding: 20px 0;
        width: 100%;
        p{
            margin: 0;
            font-family: $font-family-sub;
            font-size: 22px;
        }
    }

    .eskofinright{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 10px 0;
        p{
            margin: 0;
            color: $mainColor;
        }
    }

    .eskofinColor1{
        .eskofinleft{
            background-color: $text-color;
            border-top-right-radius: 30px;
            p{
                color: $white;
            }
        }
    }

    .eskofinColor2{
        .eskofinleft{
            background-color: $subColor;
        }
    }

    .eskofinColor3{
        .eskofinleft{
            background-color: $mainColor;
            border-bottom-left-radius: 30px;
            p{
                color: $white !important;
            }
        }
    }

    .eskofintop{
        position: relative;
        background-color: $mainColor;
        padding: 30px 15px;
        margin-top: 55px;

        p{
            color: $white;
            font-family: $font-family-sub;
            font-size: 22px;
            margin: 0;
            text-align: center;
        }

        .eskofintopImage{
            position: absolute;
            top: -50px;
            right: 10px;
        }
    }

    .eskofinbottom{
        padding: 15px;
        background-color: $subColor;
        margin-bottom: 20px;
        min-height: calc(100% - 160px);

        p{
            text-align: center;
        }
    }

    .twoColumnsImage{
        .twoColumnsImageLeft, .twoColumnsImageRight{
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            img{
                margin-top: 30px;
            }
            p{
                padding: 20px;
            }
        }
    }
}

.fullImageContainer{
    position: relative;
    @media only screen and (min-width: 768px) {
        width: 100vw;
        margin-left: calc(-50vw + 50%);
    }

    img{
        width: 100%;
        //height: 40vw;
        //object-fit: cover;
        //min-height: 500px;
        //max-height: 700px;
    }

    .content{

        top: 20px;
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 20px 0;


        @media only screen and (min-width: 768px) {
            position: absolute;

            h2, p{
                color:$white;
            }
        }


        .knop{
            display: block;
        }
    }
}

.bgGreenContainer{
    background-color: $accentColor;
    padding: 20px;

    .col-lg-5{
        min-height: 90px;
    }

    h2{
        color: $white;
        margin-bottom: 0;
    }

    .boardImage{
        width: 135px;

        @media only screen and (min-width: 992px){
            position: absolute;
            bottom: -20px;
            right: 250px;
        }

        @media only screen and (max-width: 992px){
            margin-bottom: -25px;
            margin-top: 10px;
            width: 85px;
        }
    }

    .mascotteImage{
        width: 250px;

        @media only screen and (min-width: 992px){
            position: absolute;
            bottom: -44px;
        }

        @media only screen and (max-width: 992px){
            margin-bottom: -35px;
            margin-top: 10px;
            width: 165px;
        }
    }
}
