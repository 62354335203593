@import "settings/variables.scss";

footer{
    background-color: $mainColor;
    padding: 50px 0 20px 0;
    h2,p{
        color: $white !important;
    }

    a{
        color: $white;
        transition: all .3s;

        &:hover{
            color: $text-color;
            transition: all .3s;
            text-decoration: none;
        }

        &:active, &:visited, &:focus{
            color: $white;
        }
    }

    .footerMenu{
        .level_root.horizontal{
            display: block;

            .menu_item{
                padding: 0 !important;
                margin-bottom: 6px;
                a{
                    font-size: 18px;
                    color: $white;
                    transition: all .3s;

                    &:hover{
                        color: $text-color;
                        transition: all .3s;
                        text-decoration: none;
                        padding-left: 15px;
                    }
                }
            }
        }
    }

    .contactInfo{
        .contactIcons{
            width: 35px;
            display: inline-block;
        }
    }

    .footerBottom{
        .footerMenuPrivacy{
            .level_root.horizontal{
                @media only screen and (max-width: 768px){
                    display: block;
                }
                .menu_item{
                    padding: 0 25px 0 0 !important;
                    font-size: 12px;
                }
            }
        }

        p{
            font-size: 12px;
        }
    }
}

.footer_top{
    background-color: $darkColor;
    div{
        margin-top: 120px;
        img{
            margin: -85px 0 -21px 0;
            width: 220px;
        }
    }
}

.footer_top_mvo{
    background-color: $accentColor;
}
