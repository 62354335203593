.mvoItem{
    .mvoItemLeft{
        .carousel-indicators{
            @media only screen and (min-width: 768px) {
                bottom: -35px;
            }


            li{
                width: 6px;
                height: 6px;
                border-radius: 10px;
                opacity: 1;
                border: 1px solid $mainColor;
            }

            li.active{
                background-color: $mainColor;
            }
        }
        img{
            width: 100%;
            height: 250px;
            object-fit: cover;
        }

        @media only screen and (max-width: 768px) {
            img.eskofin-border-readius{
                border-bottom-left-radius: 0;
            }
        }
    }

    .mvoItemRight{
        .mvoItemRightContent{
            background-color: $backgroundColor;
            padding: 20px;
            border-bottom-left-radius: 30px;
            min-height: 250px;
            position: relative;

            @media only screen and (min-width: 768px) {
                margin-left: -80px;
                margin-top: 40px;
                border-bottom-left-radius: 0;
                padding: 20px 50px 20px 100px;
            }

            .mvoItemRightContentLink{
                position: absolute;
                bottom: -20px;
                right: 70px;
            }
        }
    }
}

