.full_image{
    @media only screen and (min-width: 768px){
        width: 100vw !important;
        margin-left: calc(-50vw + 50%) !important;
    }

    img{
        width: 100%;
        height: 35vw;
        max-height: 800px;
        object-fit: cover;
    }

}
