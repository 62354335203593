$mainColor: #004897;
$subColor: #D9D7D7;
$accentColor: #30BF46;
$white: #fff;
$text-color: #666564;
$darkColor: #153D69;
$backgroundColor: rgba(217,215,215,.5);

$font-family: fira-sans, sans-serif;
$font-family-sub: rogue-serif, serif;

*{
    scroll-behavior: smooth;
    box-sizing: border-box;
}

.container {
    max-width: 1400px;
}

body {
    color: $text-color;
    line-height: 22px;
    width: 100vw;
    overflow-x: hidden;
    font-size: 16px !important;
    font-family: $font-family;


    h1{
        font-family: $font-family-sub;
        color: $mainColor;
        font-size: 50px;
    }

    h2{
        font-family: $font-family-sub;
        color: $mainColor;
        font-size: 30px;
    }

    h3{
        font-family: $font-family-sub;
        color: $mainColor;
        font-size: 18px;
    }

    p{
        font-family: $font-family;
        color: $text-color;
        font-size: 16px;
    }

    a{
        font-family: $font-family;
    }
}

@media only screen and (max-width: 768px){
    body{
        font-size: 14px !important;

        h1{
            font-size: 35px;
        }
        h2{
            font-size: 25px;
        }
        h3{
            font-size: 16px;
        }
    }
}

.eskofin-border-readius{
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

#contact-button{
    @media only screen and (max-width: 992px){
        display: none;
    }

    position: fixed;
    top: 400px;
    z-index: 999;
    right: -54px;
    transform: rotate(-90deg);
    background-color: $text-color;
    padding: 5px 15px;
    color: white;
    font-family: $font-family-sub;
    font-size: 20px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    transition: all .3s;

    &:hover{
        text-decoration: none;
        background-color: $mainColor;
        padding-bottom: 20px;
    }
}
