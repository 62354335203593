.testimonialItem{
    margin: 40px 0;
    .testimonialLeft{
        img{
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
        img, .embed-responsive{
            margin-bottom: 30px;
            z-index: 999;
        }
    }
    .testimonialRight{
        align-items: flex-end;
        display: flex;
        .testimonialRightContent{
            background-color: $backgroundColor;
            padding: 20px;
            min-height: 250px;

            @media only screen and (min-width: 992px) {
                margin-left: -200px;

                padding: 20px 50px 20px 215px;
            }

            .company{
                font-size: 12px;
            }
        }



    }
}
