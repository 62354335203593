@import "settings/variables.scss";

.eskofin_team{
    background-color: $backgroundColor;
    margin-top: -8px;

    .teammemberImage{
        width: 100%;
        height: 350px;
        object-fit: cover;
    }

    .teamContact{
        min-height: calc(100% - 350px);
        p{
            color: $mainColor;
        }
    }
}


.timeline{
    list-style-type: none;
    position: relative;

    &:before{
        content: ' ';
        background: $text-color;
        display: inline-block;
        position: absolute;
        left: 14px;
        top: 14px;
        width: 2px;
        height: calc(100% - 14px);
        z-index: 400;
    }

    li{
        margin: 20px 0;
        padding-left: 45px;
        position: relative;

        &:before{
            content: ' ';
            background: white;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 3px solid $mainColor;
            left: 0;
            top: 14px;
            width: 30px;
            height: 30px;
            z-index: 400;
        }

        .timelineDate{
            color: $mainColor;
        }

        .timelineContent{
            background-color: $backgroundColor;
        }
    }
}


.wrapper{
    .sections{
        width: 525%;
        .section{
            width: 75%;
        }
    }
}

@media only screen and (min-width: 776px){
    .wrapper{
        .sections{
            width: 266%;
            .section{
                width: 38%;
            }
        }
    }
}
