.pagination{
    margin: 40px 0;
    justify-content: center;

    .page-item{
        .page-link{
            color: $mainColor;
            font-family: $font-family-sub;
            border: none;
            font-size: 20px;
        }
    }

    .page-item.active .page-link{
        background-color: $mainColor;
        color: $white
    }

}
